import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const FuelSection = () => {
  const data = useStaticQuery(graphql`
    query {
      mockup1: file(relativePath: { eq: "products/fuel-mockup-1.png" }) {
        publicURL
      }
      mockup2: file(relativePath: { eq: "products/fuel-mockup-2.png" }) {
        publicURL
      }
      mockup3: file(relativePath: { eq: "products/fuel-mockup-3.png" }) {
        publicURL
      }
      shellBoxLogo: file(relativePath: { eq: "products/shell-box-logo.svg" }) {
        publicURL
      }
    }
  `)

  return (
    <section className="section section-fuel section__right-chamfer">
      <div className="container">
        <h2 className="title has-text-white">
          Vai <br className="is-hidden-desktop" />
          abastecer?
        </h2>
        <p className="subtitle">
          Simplifique e pague <br className="is-hidden-desktop" />o combustível{" "}
          <br />
          direto no Zul+.
        </p>
        <div className="columns shell-box-brand is-hidden-mobile">
          {/* TODO: mover esses styles para o arquivo correto */}
          <div className="column is-8" style={{ "padding-top": "50px" }}>
            <img
              className="is-pulled-left"
              src={data.shellBoxLogo.publicURL}
              alt="Shell Box"
              style={{ height: "86px", width: "86px" }}
            />
            <p
              className="subtitle is-pulled-left"
              style={{ "margin-left": "25px" }}
            >
              Aceito nos postos <br className="is-hidden-mobile" />
              com Shell Box.
            </p>
          </div>
        </div>
        <div className="images">
          <img
            className="fuel-mockup fuel-mockup-1"
            src={data.mockup1.publicURL}
            alt=""
          />
          <img
            className="fuel-mockup fuel-mockup-2"
            src={data.mockup2.publicURL}
            alt=""
          />
          <img
            className="fuel-mockup fuel-mockup-3"
            src={data.mockup3.publicURL}
            alt=""
          />
        </div>

        <div className="columns shell-box-brand is-hidden-desktop">
          {/* TODO: mover esses styles para o arquivo correto */}
          <div className="column is-8" style={{ "padding-top": "50px" }}>
            <img
              className="shell-box-logo is-pulled-left"
              src={data.shellBoxLogo.publicURL}
              alt="Shell Box"
            />
            <p
              className="subtitle is-pulled-left"
              style={{ "margin-left": "25px" }}
            >
              Aceito nos postos <br className="is-hidden-mobile" />
              com Shell Box.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FuelSection
